.Header_Main_Mob {
  padding: 10px;
  padding-top: 0;
  padding-left: 0;
}
.Header_Logo_Mob {
  padding: 10px;
  padding-left: 0;
  width: 40vw;
}
.OpenIcon_Mob {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.ar.OpenIcon_Mob {
  flex-direction: row-reverse;
}
.CLMob {
  color: #b84031;
  font-family: Archivo;
  font-size: 4.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5vw;
}
.OpenIcon_Mob_Button {
  background: none;
  border: none;
}
.DropdownMenu {
  background-color: #fff1e9;

  position: absolute;
  width: 100%;
  left: 0;
  top: 60px;
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1000000;
}

.DropdownMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 12px 36px;
}

.DropdownMenu li {
  cursor: pointer;
  color: #b84031;
  font-family: "Archivo", sans-serif;
  border-bottom: 1px solid #553124;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 6px;
}
.DropdownMenu_Ar li {
  text-align: right;
}

.DropdownMenu li:last-child {
  /* border-bottom: none; */
}

.DropdownMenu li:hover {
  background-color: #f5f5f5;
}
.MenuIcon_Mob {
  width: 5vw;
}
