.HomeS5P2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}
.HomeS5P2C {
  display: flex;
  align-items: center;
  gap: 8px;
}
.HomeS5P2C_Img {
  width: 2vw;
}
.HomeS5P2C_H3 {
  color: #553124;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
}
.HomeS5Mob {
  display: none;
}
@media (max-width: 768px) {
  .HomeS5P2C_Img {
    width: 10vw;
  }
  .HomeS5P2C {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    flex-direction: column;
    width: 45vw;
  }
  .HomeS5P2C_H3 {
    color: #553124;
    text-align: center;
    font-family: "Archivo", sans-serif;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 141.667% */
    letter-spacing: 1px;
    margin-top: 10px;
  }
  .HomeS5Mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
  }
  .HomeS5Web {
    display: none;
  }
}
