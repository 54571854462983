.thumbnail-gallery-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.thumbnail-gallery {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: calc(100% - 40px);
  padding: 0 20px;
  max-width: 100vw;
}
.thumbnail-gallery.center {
  justify-content: center;
}

.thumbnail-gallery.flex-start {
  justify-content: flex-start;
}

.thumbnail-gallery img {
  flex-shrink: 0;
  width: 18%;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.thumbnail-gallery img:hover {
  transform: scale(1.05);
}

.thumbnail-gallery-left-gradient,
.thumbnail-gallery-right-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  cursor: pointer;
  z-index: 1;
}

.thumbnail-gallery-left-gradient {
  left: -20px;
  background: linear-gradient(to right, rgb(254 223 204), rgba(255, 255, 255, 0));
}

.thumbnail-gallery-right-gradient {
  right: -20px;
  background: linear-gradient(to left, rgb(254 223 204), rgba(255, 255, 255, 0));
}

@media (max-width: 768px) {
  .thumbnail-gallery img {
    width: 30%;
  }
  .thumbnail-gallery-right-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    right: 0;
    cursor: pointer;
    z-index: 1;
  }
  .thumbnail-gallery-left-gradient {
    left: 0px;
    width: 30px;
  }
}

.thumbnail-gallery-left-gradient,
.thumbnail-gallery-right-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.3s ease, transform 0.3s ease;
}

.thumbnail-gallery-left-gradient .arrow {
  transform: translateX(-5px);
}

.thumbnail-gallery-right-gradient .arrow {
  transform: translateX(5px);
}

.thumbnail-gallery-left-gradient:hover .arrow,
.thumbnail-gallery-right-gradient:hover .arrow {
  color: #b84031;
  transform: scale(1.1);
}

.thumbnail-gallery-left-gradient:hover .arrow {
  transform: translateX(-10px) scale(1.4);
}

.thumbnail-gallery-right-gradient:hover .arrow {
  transform: translateX(10px) scale(1.4);
}
