.Gallery_Product_Container {
  display: flex;
  padding: 5vw 10vw;
  flex-direction: column;
  background-color: #fff1e9;
}
.Gallery_Product_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Gallery_Product_S1 a,
.Gallery_Product_S1 a:visited,
.Gallery_Product_S1 a:active,
.Gallery_Product_S1 h3,
.Gallery_Product_S1 h4 {
  color: #553124;
  text-decoration: none;
  font-size: 1.3vw;
  font-weight: 700;
  font-family: Archivo;
}
.Gallery_Product_S1 h4 {
  color: grey;
}

.Gallery_Product_S1,
.Gallery_Product_S2,
.Gallery_Product_S3 {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .Gallery_Product_S1 {
    padding-top: 70px;
  }
  .Gallery_Product_S1 a,
  .Gallery_Product_S1 a:visited,
  .Gallery_Product_S1 a:active,
  .Gallery_Product_S1 h3,
  .Gallery_Product_S1 h4 {
    text-decoration: none;
    font-size: 3vw;
    font-weight: 700;
    font-family: Archivo;
  }
}
