.AboutS1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5vw;
  background: #fedfcc;
  position: relative;
  gap: 3vw;
}
.AboutS1P1Main {
  display: flex;
}
.AboutS1::before {
  content: url("../../Assets/Svg/AboutS1P1BackG.svg");
  position: absolute;
  bottom: -4px;
  right: 0;
}
.AboutS1P1 {
  display: flex;
  flex-direction: column;
}
.AboutS1P1 h5 {
  color: #553124;
  font-family: Archivo;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.AboutS1P1 h1 {
  color: #b84031;
  font-family: Nexa;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 35vw;
}
.AboutS1P2C {
  display: flex;
  align-items: center;
}
.AboutS1P2 {
  color: #553124;
  font-family: Archivo;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1.92px;
  width: 50vw;
  z-index: 10;
}
.AboutS1P3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.HomeS4_P1_A {
  background: transparent;
}
/*  */
/*  */

.AboutS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3vw 10vw;
  gap: 5vh;
  background-color: #fff1e9;
}
.AboutS2P1 {
  align-self: flex-start;
}
.AboutS2P1 h1 {
  color: #553124;
  font-family: Nexa;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.AboutS4_P1_B {
  display: flex;
  padding: 1vw 2vw;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid #553124;
  color: #553124;
  font-family: Archivo;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: transparent;
}

.AboutS2P2 {
  display: flex;
  gap: 9vw;
}
.AboutS2P2B {
  display: flex;
  flex-direction: column;
}

.AboutS2P2Img {
  width: 30vw;
}
.AboutS2P2B h1 {
  color: #553124;
  font-family: Nexa;
  font-size: 2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.AboutS2P2B p {
  color: #553124;
  font-family: Archivo;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  height: 25vh;
  width: 35vw;
}
@media (max-width: 768px) {
  .AboutS1 {
    flex-direction: column;
    padding-top: 70px;
  }
  .AboutS1::before {
    content: url("../../Assets/Svg/AboutS1P1BackGMob.svg");
    position: absolute;
    bottom: 220px;
    right: 0;
  }
  .AboutS1P1Main {
    display: flex;
    flex-direction: column;
  }
  .AboutS1P1 {
    align-items: center;
    justify-content: center;
  }
  .AboutS1P1 h5 {
    font-size: 5vw;
    margin-bottom: 0;
  }
  .AboutS1P1 h1 {
    font-size: 5vw;
    width: 70vw;
    text-align: center;
    padding-bottom: 10px;
  }
  .AboutS4_P1_B {
    padding: 3vw 3.5vw;
    font-size: 4vw;
  }
  .AboutS1P2 {
    font-size: 4.5vw;
    font-weight: 400;
    line-height: 25px; /* 147.059% */
    letter-spacing: 0.85px;
    width: 90vw;
    z-index: 10;
    text-align: center;
  }
  .AboutS1P2 span {
    font-weight: 900;
    text-transform: uppercase;
  }
  .AboutS1P3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /*  */
  /*  */
  /*  */
  .AboutS2 {
    display: flex;
    padding: 3vw 10vw;
    gap: 3vh;
  }
  .AboutS2P1 {
    align-self: center;
  }
  .AboutS2P1 h1 {
    font-size: 5vw;
    text-align: center;
    width: 60vw;
  }
  .AboutS2P2 {
    display: flex;
    flex-direction: column;
    gap: 10vw;
    padding-bottom: 5vh;
  }
  .AboutS2P2B {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .AboutS2P2Img {
    width: 85vw;
  }
  .AboutS2P2B h1 {
    font-size: 5vw;
  }
  .AboutS2P2B p {
    color: #553124;
    font-family: Archivo;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 141.667% */
    letter-spacing: 0.92px;
    height: auto;
    width: 85vw;
    text-align: center;
  }
}
