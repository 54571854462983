@font-face {
  font-family: "Archivo";
  src: url("../../Assets/Fonts/Archivo/static/Archivo-Medium.ttf") format("truetype");
}
.Header_Main_C {
  padding-bottom: 5vw;
}
.Header_Main_Container {
  position: fixed;
  width: 100vw;
  z-index: 1000000000000;
}

.Header_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1vw 8vw;
  max-width: 100vw;
  background-color: #fff1e9;
}
.Menu {
  width: 25vw;
  justify-content: space-between;
  display: flex;
}

.Language {
  display: flex;
  align-items: center;
  gap: 24px;
}
.Header_Languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.Language_Dropdown {
  position: absolute;
  left: 0px;
  top: 60px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Header_Languages_Main {
  display: flex;
  align-items: center;
}
.Language_Dropdown_Text {
  color: #b84031;
  font-family: "Archivo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.Menu_Text,
.Menu_Text:active,
.Menu_Text:visited,
.Menu_Text_Ar,
.Menu_Text_Ar:active,
.Menu_Text_Ar:visited {
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  position: relative;
  display: inline-block;
  text-decoration: none;
  margin-top: 0.5vw;
}

.Header_Logo {
  width: 13vw;
}
.Menu_Text.active,
.Menu_Text_Ar.active {
  border-bottom: 3px solid #b84031;
  color: #b84031;
  padding-bottom: 0.5vw;
}
