.Footer_Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  background: #2e1d17;
  background-image: url("../../Assets/Svg/FooterFrame.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
  flex-direction: column;
  gap: 24px;
}
.Footer_P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FooterLogo {
  width: 100px;
}
.Footer_P1_H3,
.Footer_P4_H3 {
  color: #fff;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 337.175px;
  opacity: 0.5;
}
.Footer_P2 {
  display: flex;
  gap: 8vw;
}
.Footer_P2 a,
.Footer_P2 a:visited,
.Footer_P2 a:active {
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.205px;
  text-decoration: none;
}
.Footer_P3 {
  display: flex;
  gap: 2vw;
  margin-top: 4vh;
}
.Footer_P4_H3 {
  font-size: 14px;
}
.Footer_Container_Mob {
  background-image: url("../../Assets/Svg/FooterFrameMob.svg");
}
.Footer_P3_Mob {
  gap: 6vw;
}
.Footer_P4_H3_Mob {
  width: 200px;
}
.Footer_P4_H3_Mob_Ar {
  width: 120px;
}
@media (max-width: 768px) {
  .Footer_P2 {
    align-items: center;
    justify-content: center;
    width: 80vw;
    flex-wrap: wrap;
  }
}
