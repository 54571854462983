.HomeS3_P2_C_L {
  display: flex;
  width: 40vw;
  padding: 2vw 1vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HomeS3_P2_C_L_Main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeS3_P2_C_L_H1 {
  color: #553124;
  font-family: "Nexa", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.HomeS3_P2_C_L_P {
  color: #553124;
  text-align: center;
  font-family: Archivo;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  margin: 0;
}
.HomeS3P2C1L1 {
  width: 4vw;
}
@media (max-width: 768px) {
  .HomeS3_P2_C_L_Main {
    flex-direction: row;
    gap: 5vw;
  }
  .HomeS3_P2_C_L {
    display: flex;
    width: 80vw;
    padding: 2vw 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .HomeS3_P2_C_L_H1 {
    font-size: 4.5vw;
  }
  .HomeS3_P2_C_L_P {
    font-size: 4vw;
    line-height: 24px;
  }
  .HomeS3P2C1L1 {
    width: 7vw;
  }
}
