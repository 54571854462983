@font-face {
  font-family: "Nexa";
  src: url("../../Assets/Fonts/nexa/Nexa-Heavy.ttf") format("truetype");
  /* Adjust the path to your font file accordingly */
}

.Home_Container {
  display: flex;
  flex-direction: column;
  gap: 3.55vw;
  background-color: #fff1e9;
}
.HomeS1 {
  display: flex;
  justify-content: center;
  padding: 1.7vw 3vw;
  align-items: center;
  gap: 3vw;
}
.HomeS1_Container {
  display: flex;
  width: 40vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.27vw;
  /* padding: 50px; */
}
.HomeS1_P1 {
  display: flex;
  flex-direction: column;
  gap: 1.3vw;
}
.HomeS1_P1_H3 {
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin: 0;
}
.HomeS1_P1_H1 {
  color: #553124;
  font-family: "Nexa", sans-serif;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  width: 30vw;
}
.HomeS1_P1_P {
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  margin: 0;
}
.HomeS1Img {
  width: 40vw;
  height: 70vh;
}
.HomeS1ImgMob {
  display: none;
}
/*  */
/*  */
.HomeS2 {
  background-image: url(../../Assets/Svg/Home/HomeS2Main.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.9vw;
  position: relative;
}
.HomeS2::before {
  content: "";
  height: 82%;
  width: 5vw;
  background: linear-gradient(270deg, rgba(239, 237, 236, 0) 0%, #fedfcc 55%);
  position: absolute;
  left: 17.5%;
  z-index: 10000;
}
.HomeS2::after {
  content: "";
  height: 82%;
  width: 5vw;
  background: linear-gradient(-270deg, rgba(239, 237, 236, 0) 0%, #fedfcc 55%);
  position: absolute;
  right: 17.5%;
  z-index: 10000;
}
.HomeS2_P1_H3 {
  color: #553124;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin: 0;
  padding-top: 2vw;
}
.HomeS2_P1_H3 span {
  font-weight: 900;
}
.HomeS2_P1_H1 {
  color: #553124;
  font-family: "Nexa", sans-serif;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
/*  */
/*  */
.HomeS3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
}
.HomeS3_P1_H1 {
  color: #b84031;
  text-align: center;
  font-family: "Nexa", sans-serif;
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.HomeS3_P1_P {
  margin: 0;
  color: #553124;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  width: 35vw;
}
.HomeS3_P2_C {
  display: flex;
  gap: 3vw;
}
/*  */
/*  */
.HomeS4 {
  height: 100vh;
  background-image: url(../../Assets/Svg/Home/HomS4Back.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fedfcc;
}
.HomeS4_P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.27vw;
}
.HomeS4_P1_H1 {
  color: #b84031;
  text-align: center;
  font-family: Nexa;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.HomeS4_P1_P {
  color: #553124;
  text-align: center;
  font-family: Archivo;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  width: 51vw;
  margin: 0;
}
.HomeS4_P1_A,
.HomeS4_P1_A:active,
.HomeS4_P1_A:visited {
  text-decoration: none;
  margin-bottom: 2vh;
}
.HomeS4_P1_B {
  display: flex;
  padding: 1vw 2vw;
  justify-content: center;
  align-items: center;
  gap: 0.55vw;
  border: 2px solid #553124;
  color: #553124;
  font-family: Archivo;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: #fedfcc;
}
.HomeS4_P2 {
  display: flex;
  gap: 20px;
}
.HomeS4_P2_Mob {
  display: none;
}
.HomeS4P2Img {
  width: 20vw;
}
/*  */
/*  */
.HomeS5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HomeS5_P1 {
  width: 40vw;
}
.HomeS5_P1_H1 {
  color: #553124;
  text-align: center;
  font-family: "Nexa", sans-serif;
  font-size: 2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
/*  */
/*  */
.HomeS6 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;
}

.HomeS6_P1 {
  background: #fedfcc;
  padding: 3vw;
  position: relative;
  z-index: 2;
  width: 42vw;
}

.HomeS6P1ImgB {
  position: absolute;
  left: 0;
  top: 3vh;
  z-index: 10000;
}
.HomeS6_P1 h5 {
  color: #553124;
  font-family: "Archivo", sans-serif;

  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
.HomeS6_P1 h1 {
  color: #b84031;
  font-family: "Nexa", sans-serif;

  font-size: 2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 0;
}

.HomeS6_P1 p {
  color: #553124;
  font-family: "Archivo", sans-serif;

  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  width: 35vw;
  margin: 0;
}
.HomeS6_P1 span {
  color: #b84031;
}
.HomeS6ImgR {
  width: 35vw;
  margin-left: -3vw;
}
.HomeS6_P2 {
  position: relative;
  z-index: 3;
}
.HomeS6_P2_Mob {
  display: none;
}
.HomeS6_P2::before {
  content: "";
  position: absolute;
  background-color: #b84031;
  width: 10vw;
  height: 20vh;
  left: -80px;
  top: -30px;
  z-index: -1;
}
/*  */
/*  */
.HomeS7 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh;
}
.HomeS7P1Img {
  position: relative;
  z-index: 4;
}
.HomeS7P1Image {
  width: 35vw;
}
.HomeS7P1Img_Mob {
  display: none;
}
.HomeS7P1Img::before {
  content: "";
  position: absolute;
  background-color: #b84031;
  width: 10vw;
  height: 20vh;
  right: -30px;
  top: -30px;
  z-index: -1;
}
.HomeS7P2 {
  background: #fedfcc;
  padding: 3vw;
  position: relative;
}
.HomeS7P2ImgB {
  position: absolute;
  right: 0;
  top: 3vh;
}
.HomeS7P2 h3 {
  margin-top: 0;
  padding-left: 2vw;
  color: #553124;
  font-family: Archivo;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
.HomeS7P2 h1 {
  padding-left: 2vw;

  color: #b84031;
  font-family: Nexa;
  font-size: 2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.HomeS7P2 p {
  padding-left: 2vw;

  color: #553124;
  font-family: Archivo;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  width: 37vw;
  margin-bottom: 0;
}
/*  */
/*  */
.HomeS8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.HomeS8P1 h1 {
  color: #553124;
  text-align: center;
  font-family: Nexa;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 35vw;
}

.HomeS8P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.7vw;
}
.HomeS8P2Tes {
  display: flex;
  gap: 1.7vw;
}
.HomeS8P2Butt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7vw;
}

/*  */

/*  */
/*  */

/*  */
/*  */
/*  */
/*  */
/*  */
@media (max-width: 768px) {
  .Home_Container {
    gap: 10px;
  }
  .HomeS1 {
    flex-direction: column;
  }
  .HomeS1_P2 {
    display: none;
  }
  .HomeS1ImgMob {
    display: block;
    width: 80vw;
  }
  .HomeS1_Container {
    display: flex;
    width: 90vw;
    align-items: center;
  }
  .HomeS1_P1 {
    align-items: center;
  }
  .HomeS1_P1_H3 {
    font-size: 4.2vw;
    margin: 0;
    margin-top: 70px;
    text-align: center;
  }
  .HomeS1_P1_H1 {
    font-size: 5.4vw;
    margin: 0;
    width: auto;
    text-align: center;
  }
  .HomeS1_P1_P {
    font-size: 4vw;
    letter-spacing: 1.92px;
    margin-top: 30px;
    text-align: center;
    width: 80vw;
  }

  /*  */
  /*  */
  /*  */
  /*  */

  .HomeS2 {
    background-image: url(../../Assets/Svg/Home/HomeS2MainMob.svg);
    background-size: contain;
    height: 15vh;
  }
  .HomeS2::before {
    content: "";
    height: 82%;
    width: 5vw;
    background: linear-gradient(270deg, rgba(239, 237, 236, 0) 0%, #fedfcc 55%);
    position: absolute;
    left: 14.5%;
    z-index: 10000;
  }
  .HomeS2::after {
    content: "";
    height: 82%;
    width: 5vw;
    background: linear-gradient(-270deg, rgba(239, 237, 236, 0) 0%, #fedfcc 55%);
    position: absolute;
    right: 14.5%;
    z-index: 10000;
  }
  .HomeS2_P1_H3 {
    font-size: 3vw;
    line-height: 0;
    margin: 0px;
    margin-top: 0px;
  }
  .HomeS2_P1_H1 {
    font-size: 2.8vw;
    margin: 0;
    margin-bottom: 10px;
  }
  /*  */
  /*  */
  /*  */
  .HomeS3_P1_H1 {
    font-size: 5.4vw;
  }
  .HomeS3_P1_P {
    font-size: 4.4vw;
    width: 65vw;
  }
  .HomeS3_P2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HomeS3_P2_C {
    flex-direction: column;
  }
  /*  */
  /*  */
  /*  */
  /*  */
  .HomeS4 {
    height: auto;
    background-image: none;
    position: relative;
  }
  .HomeS4_P1 {
    background-image: url(../../Assets/Svg/HomS4BackMob.svg);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
  }
  .HomeS4_P2_Mob {
    display: flex;
    gap: 20px;
  }
  .HomeS4_P2 {
    display: none;
  }
  .HomeS4_P1_H1 {
    font-size: 5vw;
    padding-top: 10px;
  }
  .HomeS4_P1_P {
    font-size: 4.5vw;
    line-height: 18px;
    width: 80vw;
  }
  .HomeS4_P1_B {
    padding: 1.5vw 2.5vw;
    font-size: 4vw;
  }
  /*  */
  /*  */
  /*  */
  .HomeS5_P1 {
    width: 80vw;
    padding-bottom: 40px;
  }
  .HomeS5_P1_H1 {
    color: #553124;
    text-align: center;
    font-family: "Nexa", sans-serif;
    font-size: 5vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  /*  */
  /*  */
  /*  */
  /*  */
  .HomeS6,
  .HomeS7 {
    flex-direction: column;
    z-index: 1;
    padding: 2vh 0;
  }
  .HomeS6_P1,
  .HomeS7P1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 3vw 0;
  }
  .HomeS7P2 {
    overflow: hidden;
  }
  .HomeS6_P1 h5,
  .HomeS7P2 h3 {
    font-size: 3.5vw;
    margin: 0;
  }
  .HomeS6_P1 h1,
  .HomeS7P2 h1 {
    font-size: 5vw;
  }
  .HomeS6_P1 p,
  .HomeS7P2 p {
    width: 100%;
    font-size: 4vw;
    text-align: center;
    line-height: 24px;
  }
  .HomeS6P1ImgB {
    position: absolute;
    left: 25%;
    top: auto;
    bottom: 10vw;
  }
  .HomeS6ImgR {
    width: 65vw;
    margin-left: -3vw;
  }
  .HomeS6_P2 {
    position: relative;
    margin: 20px 0;
  }
  .HomeS6_P2::before {
    content: "";
    position: absolute;
    background-color: #b84031;
    width: 25vw;
    height: 10vh;
    left: -40px;
    top: -30px;
  }
  .HomeS6_P2_Web,
  .HomeS7P1Img_Web {
    display: none;
  }
  .HomeS6_P2_Mob,
  .HomeS7P1Img_Mob {
    display: block;
  }

  /*  */
  /*  */
  .HomeS7 {
    margin-top: -10px;
  }
  .HomeS7P2 h1 {
    margin-top: 0;
  }
  .HomeS7P2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .HomeS7P1Img {
    position: relative;
    z-index: 4;
  }
  .HomeS7P1Image {
    width: 65vw;
  }
  .HomeS7P1Img_Mob {
    margin-top: 20px;
  }
  .HomeS7P1Img::before {
    content: "";
    position: absolute;
    background-color: #b84031;
    width: 25vw;
    height: 10vh;
    right: -30px;
    top: -30px;
    z-index: -1;
  }
  .HomeS7P2ImgB {
    position: absolute;
    left: 25%;
    top: 45vh;
  }
}
