.MediaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.5vw;
  padding: 4.5vw 0;
  padding-bottom: 0;
  background-color: #fff1e9;
}
.MediaS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}
.MediaS1P1 {
  color: #b84031;
  font-family: Nexa;
  font-size: 3.4vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.MediaS1P2 {
  position: relative;
}

.MediaS1P2 iframe {
  width: 50vw;
  height: 30vw;
  object-fit: cover;
}

.MediaS1P2V {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
}

.play-button {
  background: transparent;
  border: none;
  width: 3vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.MediaS1P3 {
  color: #553124;
  text-align: center;
  font-family: Archivo;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 3.1vw;
  letter-spacing: 2.56px;
  width: 65vw;
}
.MediaS1P4 {
  width: 30vw;
  height: 0.1vw;
  background: rgba(85, 49, 36, 0.2);
}
/*  */
/*  */
/*  */
.MediaS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}
.MediaS2P1 {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 0.5vw;
}
.MS2SImg1 {
  width: 24.75vw; /* Equal 495px */
}
.MS2SImg2 {
  width: 20.25vw; /* Equal 405px */
}
.MS2SImg7 {
  width: 19.75vw; /* Equal 395px */
}
.MS2SImg8 {
  width: 18.25vw; /* Equal 365px */
}
.MS2SImg9 {
  width: 20.5vw; /* Equal 410px */
}
.MS2SImg11 {
  width: 16.65vw; /* Equal 333px */
}
.MS2SImg14 {
  width: 29.5vw; /* Equal 590px */
}
.MediaS3 {
  width: 100%;
}
@media (max-width: 768px) {
  .MediaContainer {
    padding: 18vw 0;
    padding-bottom: 0;
  }
  .MediaS1P1 {
    font-size: 7vw;
  }
  .MediaS1P3 {
    font-size: 4vw;
    line-height: 114%;
    width: 80%;
    letter-spacing: 0.5px;
  }
  .MediaS1P2 iframe {
    width: 80vw;
    height: 50vw;
  }
  .MediaS2 {
    padding-bottom: 5vw;
  }
  .MS2SImg1 {
    width: 35vw;
  }
  .MS2SImg2 {
    width: 30vw;
  }
  .MS2SImg7 {
    width: 30vw;
  }
  .MS2SImg8,
  .MS2SImg9,
  .MS2SImg11,
  .MS2SImg14 {
    width: 30vw;
  }
}
