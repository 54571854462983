.HomeS2_P2 {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 60vw;
  padding: 2vw 0;
}

.HomeS2Img {
  width: 13vw;
  height: auto;
  margin-right: 2vw;
}
@media (max-width: 768px) {
  .HomeS2_P2 {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    width: 70vw;
    margin-top: 0px;
    margin-bottom: 0;
  }

  .HomeS2Img {
    width: 15vw;
    height: auto;
    margin-right: 24px;
  }
}
