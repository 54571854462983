.Contact_S1 {
  display: flex;
  padding: 5vw 13vw;
  align-items: flex-start;
  gap: 8px;
  background: #fff1e9;
  flex-direction: column;
}
.Contact_S1_P1 h1 {
  color: #553124;
  font-family: Nexa;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.Contact_S1_P1 p {
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
}

.Contact_S1_P2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.ContactS1Ab {
  position: absolute;
  right: 0;
}
.ContactS1Ab img {
  width: 20vw;
}
.contact_part2_second {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 30px;
  gap: 64px;
}

.con_part2_Input1 {
  display: flex;
  margin-bottom: 20px;
  gap: 30px;
  width: 70vw;
}

.con_part2_Input1_Chi {
  display: flex;
  align-items: flex-start;
  gap: 3vw;
}

.input_1::placeholder {
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
}

.input__field,
.input__field2 {
  padding: 15px;
  padding-left: 0;
  border-bottom: 3px solid #553124;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  background: #fff1e9;
  flex-grow: 1; /* Allow the input fields to grow and fill the available space */
  width: 31.7vw;
  color: #553124;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.input__field2 {
  width: 67.5vw;
}

/* input 3 for country and phone number */
.con_part2_Input2 {
  margin-bottom: 20px;
}

input::-webkit-input-placeholder {
  color: #fff1e9;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.contact_part2_third {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 30px;
}
.contact_part2_third_s1 {
  display: flex;
  width: 670px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 70vw;
}
.solutionQuestion {
  color: #1e0d12;
  font-family: "Archivo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.Contact_submitButton {
  width: 68.3vw;
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 3px solid #553124;
  cursor: pointer;
  background-color: #fff1e9;
}
.contact_part2_third_s2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ababab;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  padding: 50px 0;
}

/* Autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f1 inset;
  box-shadow: 0 0 0px 1000px #f3f3f1 inset;
  -webkit-text-fill-color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Autofill styles for the specific field classes */
.input__field:-webkit-autofill,
.input__field:-webkit-autofill:hover,
.input__field:-webkit-autofill:focus,
.input__field:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px #fff1e9 inset;
  box-shadow: 0 0 0px 1000px #fff1e9 inset;
  -webkit-text-fill-color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input__field2:-webkit-autofill,
.input__field2:-webkit-autofill:hover,
.input__field2:-webkit-autofill:focus,
.input__field2:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px #fff1e9 inset;
  box-shadow: 0 0 0px 1000px #fff1e9 inset;
  -webkit-text-fill-color: #553124;
  font-family: "Archivo", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Contact_S2 {
  display: flex;
  padding: 0vw 0vw 0vw 11vw;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff1e9;
  flex-direction: column;
}

.Contact_S2_P1_L h1 {
  color: #553124;
  font-family: Nexa;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  width: 25vw;
  margin-bottom: 50px;
}
.Contact_S2_P1_L h3 {
  color: #553124;
  font-family: Nexa;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
.Contact_S2_P1_L_H5 {
  color: #553124;
  font-family: Nexa;
  font-size: 0.8vw;
  font-style: normal;
  line-height: 34px;
  letter-spacing: 1px;
  width: 30vw;
  font-weight: 300;
}
.Contact_S2_P1_L_H5 strong {
  font-family: Nexa;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1px;
}
.Contact_S2_P1_L_Phone {
  display: flex;
  gap: 5vw;
}
.Contact_S2_P1_L_Phone h6 {
  font-family: Nexa;
  font-size: 1vw;
  color: #553124;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.Contact_S2_P1_L_Phone h7 {
  color: #553124;
  font-family: Nexa;
  font-size: 0.8vw;
  font-style: normal;
  letter-spacing: 1px;
  width: 30vw;
  font-weight: 300;
  margin-top: 0;
}
.Contact_S2_P1_L_H3 {
  color: #553124;
  font-family: Nexa;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.Contact_S2 {
  display: flex;
  padding: 0 0 0 11vw;
  align-items: flex-start;
  justify-content: space-between;
  background: #fedfcc;
  flex-direction: column;
}

.Contact_S2_P1 {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  gap: 10vw;
  width: 100%; /* Ensure it takes the full width */
  height: auto; /* Auto height to fit the content */
  flex-grow: 1; /* Allow it to grow to fill the available space */
}

.Contact_S2_P1_L {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Contact_S2_P1_R {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ContactS2Img {
  width: 45vw;
  height: 100%;
  object-fit: cover;
}

/*  */
/*  */
/*  */
/*  */

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

.process-container {
  text-align: center;
  font-family: Cairo;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h2 {
  color: #3b3b3b;
  margin-bottom: 20px;
}
.process_Step_Cont {
  display: flex;
  gap: 32px;
}
.process-step {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 20px;
  padding: 30px;
  min-width: 35vw;
  position: relative;
}

.step-number {
  position: absolute;
  top: -10px;
  right: -40px;
  font-size: 24px;
  color: #132037;
  background: #ffffff;
  border: 2px solid #132037;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.step-content {
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  display: flex;
  gap: 22px;
}

.step-content img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.step-content h3 {
  color: #132037;
  font-size: 22px;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
}

.step-content p {
  color: #132037;
  font-size: 16px;
}

.captchaModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.captchaModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
  max-width: 90%;
}

.captchaModalContent h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.captchaModalContent p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}

.captchaModalContent input {
  width: 91%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #553124;
}
.captchaModalContent input::placeholder {
  color: #553124;
}

.captchaModalContent button {
  width: 100%;
  padding: 10px;
  background-color: #fedfcc;
  color: #553124;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.captchaModalContent button:hover {
  background-color: #9a4d1d;
  color: #fff;
}

.captchaModalContent button:last-child {
  background-color: #e53935;
  margin-top: 10px;
  color: #fff;
}

.captchaModalContent button:last-child:hover {
  background-color: #e53935;
}

@media (max-width: 575px) {
  .process_Step_Cont {
    display: flex;
    gap: 0px;
    flex-direction: column-reverse;
  }
  .process-step {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 20px 0;
    padding: 30px;
    min-width: auto;
    position: relative;
  }
  .process-container {
    text-align: center;
    font-family: Cairo;
    display: block;
    margin-right: 20px;
  }
  .Contact_S1 {
    padding: 20vw 11vw;
    padding-bottom: 2vw;
    align-items: center;
    background: #fff1e9;
    flex-direction: column;
  }
  .Contact_S1_P1 h1 {
    font-size: 5vw;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
  }
  .Contact_S1_P1 p {
    font-size: 4.5vw;
    text-align: center;
    line-height: 25px; /* 141.667% */
    letter-spacing: 0.92px;
  }
  .con_part2_Input1_Chi {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
  }
  .input_1::placeholder {
    font-size: 4vw;
    line-height: normal;
    margin-left: 20px;
  }
  .input__field,
  .input__field2 {
    width: 85vw;
    font-size: 4vw;
  }
  .Contact_S1_P2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact_part2_second {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    margin-top: 30px;
    gap: 0px;
  }
  .con_part2_Input1 {
    display: flex;
    margin-bottom: 20px;
    gap: 30px;
    width: 90vw;
  }
  .solutionQuestion {
    color: #1e0d12;
    font-family: "Archivo", sans-serif;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
  }
  .solutionQuestion span {
    color: #a3a3a1;
  }
  .contact_part2_third_s1 {
    display: flex;
    width: 670px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 90vw;
  }
  .contact_part2_third_s2 {
    padding: 10px 0;
  }
  /*  */
  /*  */
  /*  */
  .Contact_S2_P1 {
    flex-direction: column-reverse;
  }
  .ContactS2Img {
    width: 100vw;
  }
  .Contact_S2 {
    padding: 0;
  }
  .Contact_S2_P1_L {
    flex: 1;
    width: 90vw;
    align-items: center;
  }
  .Contact_S2_P1_L h1 {
    font-size: 5vw;
    width: 80vw;
    text-align: center;
    margin-bottom: 0;
  }
  .Contact_S2_P1_L h3 {
    font-size: 4vw;
  }
  .Contact_S2_P1_L_H5 {
    font-size: 3.5vw;
    width: 80vw;
    text-align: center;
  }
  .Contact_S2_P1_L_H5 strong {
    width: 80vw;
    font-size: 5vw;
    text-align: center;
  }
  .Contact_S2_P1_L_H3 {
    margin-bottom: 3vh;
  }
  .Contact_S2_P1_L_Phone {
    width: 90vw;
    justify-content: space-between;
    margin-top: -3.5vh;
  }
  .Contact_S2_P1_L_Phone h6 {
    font-size: 4vw;
    text-align: center;
  }
  .Contact_S2_P1_L_Phone h7 {
    font-size: 3vw;
    font-weight: lighter;
    text-align: center;
  }
  .Contact_Numbers_Phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3.5vh;
  }
  .input__field:-webkit-autofill,
  .input__field:-webkit-autofill:hover,
  .input__field:-webkit-autofill:focus,
  .input__field:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0px 1000px #fff1e9 inset;
    box-shadow: 0 0 0px 1000px #fff1e9 inset;
    -webkit-text-fill-color: #553124;
    font-family: "Archivo", sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .input__field2:-webkit-autofill,
  .input__field2:-webkit-autofill:hover,
  .input__field2:-webkit-autofill:focus,
  .input__field2:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0px 1000px #fff1e9 inset;
    box-shadow: 0 0 0px 1000px #fff1e9 inset;
    -webkit-text-fill-color: #553124;
    font-family: "Archivo", sans-serif;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
