.ContactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fedfcc;
  padding: 2vw;
}
.ContactSection h3 {
  color: #553124;
  font-family: Archivo;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.ContactSection h1 {
  color: #b84031;
  font-family: Nexa;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.ContactSection a,
.ContactSection a:visited,
.ContactSection a:active {
  text-decoration: none;
}
@media (max-width: 768px) {
  /*  */
  .ContactSection {
    margin-top: -10px;
    padding: 30px;
  }
  .ContactSection h3 {
    font-size: 4.4vw;
    margin: auto;
    text-align: center;
  }
  .ContactSection h1 {
    font-size: 5vw;
    text-align: center;
  }
}
