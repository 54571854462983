.GalleryS1 {
  background-color: #fff1e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.GalleryS1_P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 56px;
}

.GalleryS1_P1 h1 {
  color: #b84031;
  text-align: center;
  font-family: Nexa;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
}

.GalleryS1_P1 p {
  color: #553124;
  font-family: Archivo;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 141.667% */
  letter-spacing: 1px;
  width: 53vw;
  text-align: center;
  margin-top: 0;
}

.GalleryS1_P1 h3 {
  color: #553124;
  font-family: Archivo;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 141.667% */
  letter-spacing: 1px;
  width: 55vw;
  text-align: center;
}

.GalleryS1_B {
  display: flex;
  padding: 0.5vw 1.5vw;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 2px solid #553124;
  color: #553124;
  font-family: Archivo;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  background-color: #fedfcc;
}

.GalleryS2 {
  padding: 56px 0;
  background-color: #fff1e9;
}

.GalleryS2_Space {
  display: flex;
  align-items: center;
  justify-content: center;
}
.GalleryS3Com {
  padding-bottom: 56px;
  background-color: #fff1e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4vw;
}
.GalleryS3T {
  color: #553124;
  text-align: center;
  font-family: Archivo;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .GalleryS1_P1 {
    padding-top: 100px;
  }
  .GalleryS1_P1 h1 {
    font-size: 4.5vw;
  }
  .GalleryS1_P1 p {
    font-size: 4vw;
    width: 60vw;
    font-weight: 700;
    line-height: 25px;
    padding-top: 20px;
  }
  .GalleryS1_P1_Mob {
    padding-top: 10px;
  }
  .GalleryS1_P1 h3 {
    font-size: 4vw;
    line-height: 24px;
  }
  .GalleryS1_B {
    font-size: 3.5vw;
    padding: 2vw 2.5vw;
  }
  .GalleryS2_Space img {
    width: 70vw;
  }
  .GalleryS3T {
    font-size: 4vw;
  }
}
