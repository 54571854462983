.GalleryS3 {
  background-color: #fff1e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* e8e8e0 */

.categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 70px;
  width: 60vw;
}

.category-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 20px;
  margin: 5px;
  border: 1px solid #553124;
  background-color: transparent;
  cursor: pointer;
  color: #553124;
  font-family: Archivo;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 100px;
}

.category-button.active {
  background-color: #b84031;
  border: none;
  color: #fff;
}

.category-button.active path {
  fill: #fff;
}

.icon svg {
  width: 28px;
}

.uniform-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3vw;
}

.uniform-item-link {
  text-decoration: none;
}

.uniform-item {
  margin: 10px 0;
  text-align: center;
  display: flex;
  align-items: center;
}

.uniform-item-background,
.uniform-item-main-image {
  width: 200px;
  height: 250px;
}
.uniform-item-background {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.uniform-item-main-image {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.uniform-item-background {
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
}

.uniform-item-background .view-details-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #7f5242;
  font-family: Archivo;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.uniform-item-main-image {
  width: 200px;
  object-fit: cover;
  margin-left: -1px;
}

.uniform-item-name {
  text-align: start;
  color: #b84031;
  font-family: Nexa;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 900;
  line-height: 44px;
  letter-spacing: 1px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 10px;
}

@media (max-width: 768px) {
  .categories {
    padding: 0;
    width: 85vw;
  }
  .category-button {
    font-size: 3.5vw;
    gap: 4px;
    padding: 0 6px;
  }
  .category-button:first-child {
    display: none;
  }
  .icon svg {
    width: 20px;
  }
  .uniform-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0vw;
    column-gap: 7vw;
  }
  .uniform-item-background,
  .uniform-item-main-image {
    width: 85px;
    height: 100px;
  }
  .uniform-item-name {
    text-align: start;
    line-height: 15px;
    letter-spacing: 1px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .uniform-item-name {
    font-size: 1.6vw;
  }
  .uniform-item-background .view-details-button {
    font-size: 2vw;
  }
}
