.Contact_Button {
  display: flex;
  padding: 11px 13px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #b84031;
  border: none;
  color: #fff;
  font-family: "Archivo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
}
.Contact_Text,
.Contact_Text:active,
.Contact_Text:visited {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 768px) {
  .Contact_Button {
    padding: 12px 12px;
    font-size: 6.3vw;
  }
}
