.choice-button {
  padding: 10px 20px;
  margin: 0px;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 16vw;
}

.choice-button:not(.selected) {
  border: 2px solid #000;
  color: #553124;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  background: transparent;
}

.choice-button.selected {
  border: 1px solid #d4d4d4;
  background: #b84031;
  color: #fff;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.choice-svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.choice-svg.active path {
  fill: #fff;
}
.choices-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 70vw;
}
@media (max-width: 768px) {
  .choice-button {
    padding: 10px 10px;
    margin: 0px;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 40vw;
  }

  .choice-button:not(.selected) {
    font-size: 16px;
  }
  .choice-button.selected {
    font-size: 16px;
  }
  .choices-container {
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    width: 95vw;
  }
  .choice-button:not(.selected) {
    border: 2px solid #000;
    color: #553124;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    background: transparent;
  }
  .choice-svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}
