.image-slider {
  display: flex;
  align-items: center;
  gap: 10vw;
  width: 100%;
  justify-content: center;
}

.image-slider img {
  max-width: 100%;
  max-height: 400px;
}

.image-slider button {
  background: none;
  border: none;
  cursor: pointer;
}

.ButtoN_Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b84031;
  border-radius: 50%;
  width: 70px;
  height: 60px;
  padding-right: 5px;
}
.ButtoN_Icon2 {
  background-color: #b84031;
  border-radius: 50%;
  transform: rotate(180deg);
  padding-right: 5px;
  width: 70px;
  height: 60px;
}
@media (max-width: 768px) {
  .image-slider img {
    max-width: 50vw;
  }
  .ButtoN_Icon {
    width: 50px;
    height: 40px;
  }
  .ButtoN_Icon2 {
    width: 40px;
    height: 40px;
  }
}
