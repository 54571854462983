.social-media-container {
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}

.social-media-container img {
  width: 20px; /* Set icon size */
  height: 20px;
  margin: 10px 0; /* Space between icons */
}

@media (min-width: 768px) {
  .social-media-container {
    position: fixed;
    top: 30%;
    right: 30px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* for left text in home page section two , career section two , about us section two */
  html[lang="en-US"] .Dev_Align_EN_Left {
    text-align: left !important;
    margin-left: 0 !important ;
  }
  html[lang="en-US"] .Container_Dev_Align_EN_Left {
    display: flex !important;
    flex-direction: row-reverse !important;
    gap: 50px !important;
  }
  html[lang="en-US"] .Container_Dev_Align_EN_Left_Career {
    display: flex !important;
    flex-direction: row-reverse !important;
    gap: 50px !important;
  }

  /* for reverse menu item in all website */

  html[lang="en-US"] #menu-webmenu {
    display: flex !important;
    /*     flex-direction: row-reverse !important; */
  }
  /* for reverse menu item in home and contact us */

  /* 	,
  html[lang="en-US"] .page-id-1789 #menu-webmenu */

  html[lang="en-US"] .page-id-24 #menu-webmenu,
  html[lang="en-US"] .page-id-40 #menu-webmenu {
    flex-direction: row !important;
  }

  html[lang="en-US"] .Container_Dev_Image {
    margin-top: 100px;
  }

  html[lang="en-US"] .Container_Dev_Button_Home {
    display: flex;
    /* justify-content: flex-end; */
  }
  html[lang="en-US"] #Container_Dev_Button_Footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  html[lang="en-US"] .page-id-24 #Container_Dev_Button_Footer,
  html[lang="en-US"] .page-id-40 #Container_Dev_Button_Footer,
  html[lang="en-US"] .page-id-1789 #Container_Dev_Button_Footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse !important;
    width: 100%;
  }
  html[lang="en-US"] #Container_Dev_Button_Footer_Button {
    display: flex;
    justify-content: flex-start;
  }

  html[lang="en-US"] Container_Dev_Button_Footer_Button_Mess {
    display: flex;
    justify-content: flex-start;
  }

  html[lang="en-US"] .page-id-24 #Container_Dev_Button_Footer_Button,
  html[lang="en-US"] .page-id-40 #Container_Dev_Button_Footer_Button,
  html[lang="en-US"] .page-id-1789 #Container_Dev_Button_Footer_Button {
    justify-content: flex-start !important;
  }

  html[lang="en-US"] #Container_Dev_Button_Footer_Button_Con {
    display: flex !important;
    justify-content: flex-start !important;
  }

  html[lang="en-US"] .page-id-24 #Container_Dev_Button_Footer_Button_Con,
  html[lang="en-US"] .page-id-40 #Container_Dev_Button_Footer_Button_Con,
  html[lang="en-US"] .page-id-1789 #Container_Dev_Button_Footer_Button_Con {
    justify-content: flex-start !important;
  }
  html[lang="en-US"] .et_pb_column_1_tb_footer et-last-child {
    display: flex;
    justify-content: flex-end;
  }

  html[lang="en-US"] .Container_Dev_Button_Contact,
  html[lang="en-US"] .dsm_contact_form_7_0 .wpcf7-form label,
  html[lang="en-US"] .dsm_contact_form_7_1 .wpcf7-form label,
  html[lang="en-US"] .dsm_contact_form_7_1.dsm_contact_form_7 .wpcf7-form-control.wpcf7-text,
  html[lang="en-US"] .dsm_contact_form_7_0.dsm_contact_form_7 .wpcf7-form-control.wpcf7-text {
    text-align: left !important;
  }
}
